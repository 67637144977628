<template>
    <div class="sign-in-page" v-if='display'>
        <header class="sign-in-header">
            <img v-if="isNativeApp()" src="../../public/Invrs-Logo-Green-Blue.png" class="nav-image" />
            <img v-else src="../../public/Invrs-Logo-Green-Blue.png" class="nav-image" @click="goHome()" />
            <Button label="Sign Up" class="p-button-text sign-up-button" @click="goToSignUpPage()" />
        </header>

        <div class="sign-in-main-content">
           

            <div class="social-button-container" v-if="!isNativeApp()">
                <Button label="Sign In Using Google" class="social-button" @click="socialSignInClick('google')">
                    <img class="social-button-img" alt="Google Logo" src="../../public/google-logo.png" />
                    <span class="social-button-text">Sign in with Google</span>
                </Button>
            </div>
            <template v-if="showSystemStatus">
                <Message  severity="warn" :closable="false"><span v-html="systemStatus"></span></Message>
            </template>
            <template v-if="showOrientationWarning">
                <Message  severity="warn" ><span>Based on your screen size you should change your device orientation to landscape mode.</span></Message>
            </template>
            <div class="sign-in-container">
                <div class="sign-in-title-container">
                    <span class="sign-in-title">Welcome Back!</span>
                    <!-- <img src="/assets/images/login-signup-graphic.png" v-if="isMobile()"/> -->
                </div>
                <!-- <span class='sign-in-subtext' v-if="quote">{{ quote }}</span> -->
                 <section>
                    <div class="container">
                        
                        <div class="section-header">
                        
                        <span class="heading-secondary">Announcing the <img src="/assets/qi-logo.svg" width="100" style="vertical-align:bottom;"/> Summer Stock Showdown!</span>
                        <p class="heading-description">
                            
                            <small>July 22 - August 30<br>
                        $1,700 in prizes<br>
                        Free entry, full contest details <a target="_" :href="HOME+'/showdown-rules'">here.</a>
                            </small>   
                        </p>
                        
                        </div>
                        
                    </div>
                    
                </section>   

                <div class="input-container">
                    <span>
                        <InputText id="username" placeholder="Email" type="text" v-model="userName" />
                    </span>
                    <span>
                        <InputText id="password" placeholder="Password" type="password" v-model="password" />
                    </span>
                </div>
                <div class="button-container">
                    <Button label="Forgot Password?" class="p-button-text forgot-password-button" @click="openForgotPasswordModal" />

                    <Button label="Sign In" class="sign-in-button" @click="signIn" :loading="loading" :disabled="!isUsernameAndPasswordFilledIn"/>
                </div>
                <ForgotPasswordModal ref="forgotPasswordModal" />
            </div>
        </div>
        <Toast position="center" group="center" style="z-index:30000;" :autoZIndex="false" />
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { HOME, APP_PAGE } from '@/common/config';

import AppService from '../service/AppService';
import LocalStorageService from '../service/LocalStorageService';
import UserService from '../service/UserService';


//import ForgotPasswordModal from '../components/modal/ForgotPasswordModal';
import Toast from 'primevue/toast';
import Message from 'primevue/message';

import JwtUtils from '../utilities/JwtUtils';
import BrowserUtils from '../utilities/BrowserUtils';
import SessionStorageUtils from '../utilities/SessionStorageUtils';
import EventBus from '../event-bus';

import SocialAuthService from '../service/SocialAuthService.js';
import AdminService from '../service/AdminService';

const SUCCESS = '1';
export default {
    name: 'Login',

    components: {
        ForgotPasswordModal: defineAsyncComponent(() =>
            import('../components/modal/ForgotPasswordModal')
            ),
        Toast,
        Message,
    },

    computed: {
        isUsernameAndPasswordFilledIn() {
            return this.userName && this.password;
        }
    },

    data() {
        return {
            HOME,
            userName: null,
            password: null,
            loading: false,
            display: false,
            quote: null,
            showSystemStatus: false,
            systemStatus: '',
            showOrientationWarning: false,

        };
    },
    mounted() {
        this.showOrientationWarning = false;
        try {
            AdminService.getServerStatus().then(resp=> {
                //console.log('resp: ', resp)
                if( resp.data.status != 'OK') {
                    //console.log("resp.data.status = ["+ resp.data.status+"]")
                    this.systemStatus = resp.data.extMessage;
                    this.showSystemStatus = true;
                }
                else {
                    this.systemStatus = resp.data.extMessage;
                    this.showSystemStatus = false;
                }

            })
        }catch( error) {
            console.log("error getting server status... ignoring, Error was :", error);
            this.showSystemStatus = false;
        }


        window.addEventListener('keyup', (e) => {
            if (e.key === 'Enter') {
                if (this.isUsernameAndPasswordFilledIn) {
                    this.signIn();
                }
            }
        })

        SocialAuthService.init();

        const inviteCode = this.$route.query.invite;
        const authSession = JSON.parse(LocalStorageService.getString('INVRS_AUTH_SESSION'));
        const resetToLogin = () => {
            AppService.cleanUpApp();
            this.display = true;
            // UserService.getQuoteOfTheDay().then(resp => {
                
            //     if( resp.data.status == "success") {
            //         this.quote = resp.data.quote;
            //     }
            // }).catch(err => {
            //     this.display = true;
            //     console.log(err);
            // });

            
            if(  window.innerWidth > 760 && window.innerWidth < 991 ){
                this.$nextTick(() => {
                    //this.$toast.add({severity: 'warn', summary: "Switch to landscape mode.", detail:"Based on your screen size you should change your device orientation to landscape mode.",  life:20000, group: 'center'});
                    this.showOrientationWarning = true;
                })
                
            }
        }

        if( inviteCode ){
            LocalStorageService.storeGroupInviteCode(inviteCode);
        }

        if (authSession) {
            const parsedToken = JwtUtils.parseJwt(authSession.accessToken);
            const expiryDate = new Date(parsedToken.exp*1000);
            const now = new Date();

            if (expiryDate > now) {
                this.goToApp();
            } else {
                resetToLogin();
            }
        } else {
            resetToLogin();
        }
    },

    methods: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        isNativeApp() {
            return BrowserUtils.isNativeApp();
        },

        triggerEnter() {
            if (this.isUsernameAndPasswordFilledIn) {
                this.signIn();
            }
        },

        goToApp() {
            sessionStorage.removeItem('goToGroup');
            sessionStorage.removeItem('goToCourseModule');
            sessionStorage.removeItem('goToQAFeedPostId');
            sessionStorage.removeItem('goToOwnProfile');
            sessionStorage.removeItem('goToQAFeed');
            sessionStorage.removeItem('goToContest');
            sessionStorage.removeItem('registerForContest');
            sessionStorage.removeItem('goToPuzzle');

            const groupProfileId = this.$route.query.goToGroup;
            const goToCourseModule = this.$route.query.courseId;
            const goingToOwnProfile = this.$route.query.goToOwnProfile;
            const goToQAFeed = this.$route.query.goToQAFeed;
            const goToQAFeedPostId = this.$route.query.postId;
            const contestId = this.$route.query.goToContest;
            const courseRegCode = this.$route.query.courseRegCode;
            const registerForContest = this.$route.query.contestId;
            const puzzleId = this.$route.query.goToPuzzle;

            if (groupProfileId !== undefined) {
                sessionStorage.setItem('goToGroup', groupProfileId);

                if (goToCourseModule !== undefined) {
                    sessionStorage.setItem('goToCourseModule', goToCourseModule);
                } 

                if(goToQAFeedPostId !== undefined){
                    sessionStorage.setItem('goToQAFeedPostId', goToQAFeedPostId);
                }
            }

            if (goingToOwnProfile !== undefined) {
                sessionStorage.setItem('goToOwnProfile', true);
            }

            if (goToQAFeed !== undefined && goToQAFeedPostId !== undefined) {
                sessionStorage.setItem('goToQAFeed', goToQAFeed);
                sessionStorage.setItem('goToQAFeedPostId', goToQAFeedPostId);
            }

            if (contestId !== undefined) {
                sessionStorage.setItem('goToContest', contestId);
            }

            if (puzzleId !== undefined) {
                sessionStorage.setItem('goToPuzzle', puzzleId);
            }

            if (registerForContest !== undefined) {
                sessionStorage.setItem('registerForContest', JSON.stringify(registerForContest));
            }

            if(courseRegCode !== undefined) {
                 sessionStorage.setItem('courseRegCode', courseRegCode);
            }

            if (!SessionStorageUtils.getParsedValue('loggedInAlready')) {
                sessionStorage.setItem('loggedInAlready', true);
            }

            window.location.href = APP_PAGE;
        },
        goHome() {
            window.location.href = HOME;
        },
        goToSignUpPage() {
            this.$router.push('signup');
        },

        openForgotPasswordModal() {
            this.$refs.forgotPasswordModal.open();
        },

        socialSignInClick(socialAccountType) {
            SocialAuthService.socialAuth(socialAccountType, (socialAuthCode) => {
                if (socialAuthCode) {
                    UserService.socialLogin(socialAuthCode, socialAccountType).then((resp) => {
                        if (resp.data.status == SUCCESS) {
                            try {
                                EventBus.emit('track-login', this.userName);
                            } catch (err) {
                                this.goToApp();
                            }
                            this.goToApp();
                        } else {
                            let msg = resp.data.msg ? resp.data.msg : 'Sorry an expected error occurred during login.';
                            this.$toast.add({ severity: 'error', summary: msg, life: 2500, group: 'center' });
                            this.loading = false;
                        }
                    });
                } else {
                    let msg = 'Sorry an expected error occurred during login.';
                    this.$toast.add({ severity: 'error', summary: msg, life: 2500, group: 'center' });
                    this.loading = false;
                    // show error
                }
            });
        },

        signIn() {
            this.loading = true;
            UserService.login(this.userName, this.password).then(resp => {
                if (resp.data.status == SUCCESS) {
                    try {
                        EventBus.emit('track-login', this.userName);
                    } catch (err) {
                        this.goToApp();
                    }
                    this.goToApp();
                } else {
                    let msg = resp.data.msg ? resp.data.msg : 'Sorry an expected error occurred during login.';
                    this.$toast.add({ severity: 'error', summary: msg, life: 2500, group: 'center' });
                    this.loading = false;
                }
            });
        },
    },
};
</script>

<style scoped>
* {
    font-family: 'Trebuchet MS', 'Verdana';
}

::v-deep(.p-message.p-message-warn) {
    width: 500px;
}


.sign-in-page {
    background-image: url(/assets/images/login-signup-graphic.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.sign-in-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 30px;
    position: fixed;
    width: 100%;
    z-index: 999;
}

.nav-image {
    height: 50px;
    cursor: pointer;
}

.p-button {
    padding: 0;
}

.p-button-text {
    font-weight: bold;
    color: #32364e;
}
.p-button-text:enabled:hover {
    background: none;
    color: #33cc99;
}
.p-button-text:enabled:focus {
    box-shadow: none;
    border: none;
}
.forgot-password-button {
    font-size: 20px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
}
.sign-up-button {
    font-size: 24px;
}

.sign-in-main-content {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}

.sign-in-container {
    background: #fff;
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1.5rem;
}

.sign-in-title-container {
    display: flex;
    align-items: center;
}

.sign-in-title {
    font-size: 46px;
    color: #32364e;
    line-height: 46px;
    margin-bottom: 10px;
}

.input-container {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.sign-in-subtext {
    font-size: 20px;
    color: #a6a6a6;
    margin-top: 10px;
}

.p-inputtext {
    width: 100%;
    font-size: 20px;
    border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
}
.p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #33cc99;
}
#username {
    margin-bottom: 25px;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-in-button {
    border-radius: 8px;
    background-color: #33cc99;
    font-size: 24px;
    width: 100%;
    padding: 10px 20px;
    color: white;
    border: 1px solid #33cc99;
}
.sign-in:enabled:focus {
    box-shadow: none;
    border-color: #33cc99;
}

.social-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.social-button {
    margin-bottom: 25px;
    border-radius: 8px;
    font-size: 24px;
    width: 500px;
    padding: 10px 20px;
    color: black;
    border: 1px solid #f2f4fa;
    background: #fff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.social-button:hover {
    background-color: white;
    border-color: #32364e;
    color: black;
    cursor: pointer;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
}
.social-button-text {
    width: 100%;
}
.social-button-img {
    width: 1.5rem;
}

@media screen and (max-width: 1440px) and (min-width: 768px) {
    .sign-in-page {
        background-position-x: calc(100% + 150px);
    }
    .sign-in-title {
        font-size: calc(30px + (50 - 30) * ((100vw - 768px) / (1440 - 768)));
    }

    .sign-in-subtext {
        font-size: calc(16px + (20 - 16) * ((100vw - 768px) / (1440 - 768)));
    }

}

@media (max-width: 976px) {
    .sign-in-page {
        background-position-x: calc(100% + 250px);
    }
}

@media (max-width: 760px) {
    ::v-deep(.p-message.p-message-warn) {
        width: 90%;
    }

    .sign-in-page {
        background: none;
    }
    .sign-in-main-content {
        background: #f2f4fa;
         justify-content: flex-end;
    }

    .forgot-password-button {
        font-size: 18px;
    }

    

    .sign-in-title-container {
        position: relative;
    }

    .sign-in-title-container img {
        width: 90%;
        height: 135px;
        margin-left: auto;
    }
    .sign-in-title {
        /* position: absolute; */
        font-size: 26px;
    }
    .sign-in-container {
        padding: 15px;
        width: 90%;
        max-width: 500px;
    }

    .sign-in-subtext {
        width: 100%;
        color: #bfbfbf;
        font-size: 16px;
    }

    .social-button {
        width: 90%;
        max-width: 500px;
    }

    .sign-in-header {
        padding: 0 20px;
        height: 60px;
    }

    .sign-up-button {
        font-size: 18px;
    }

    .nav-image {
        height: 30px;
    }

    .sign-in-button {
        font-size: 20px;
    }

    .p-inputtext {
        width: 100%;
        font-size: 18px;
        border-radius: 8px;
        border: 2px solid #bfbfbf;
        background-color: #f2f4fa;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
    }

    .input-container {
        margin-bottom: 25px;
    }
}

@media (max-width: 592px) {
    .sign-in-title {
        font-size: 24px;
    }
}


@media (max-width: 280px) {
    .sign-in-title {
        font-size: 18px;
    }
    .sign-in-title-container img {
        width: 95px;
        height: 75px;
    }
}

</style>

<style scoped>
/** for competition */
section {
  padding-left: 3.6rem;
  padding-right: 3.6rem;
  background-color: #32364e;
  border-radius: 16px;
  /* transform: skewY(3deg); */
  box-shadow: inset 4.8rem 4.8rem 9.6rem #2b2e42,
    inset -4.8rem -4.8rem 9.6rem #3a3e5a;

}

.container {
  /* transform: skewY(-3deg); */
  /* width: 100%; */
}

.section-header {
  display: flex;
  flex-direction: column;
  text-align:center;
}

.subheading {
  color: #33cc99;
}

.heading-secondary {
  color: #fff;
  font-size: 1.8rem;
  padding-top:.5rem; 
  margin-bottom: 0;
}

.heading-description {
  /* line-height: 3.5rem; */
  /* width: 60%; */
  color: #f2f4fa;
  /* margin-bottom: 4.8rem; */
  padding-bottom:.5rem;
}



.section-data {
  transition: all 0.3s ease-in;
}

.hidden {
  transform: skewY(-3deg);
  transform: translateY(4.8rem);
  opacity: 0;
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
  
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  

  .heading-description {
    width: 100%;
  }

  .slide-icon {
    width: 7%;
    margin-right: 1.6rem;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  
}
</style>
